import React from 'react';
import { Row, Col, Input, Form, Label, FormGroup, Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../../Helper';
import CustomerEditMenu from './CustomerEditMenu';
import './Admin.css';
import Button from '../Button';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	document?: any;
}
interface IState {
	DocumentId: string;
	DocumentNumber: string;
	ProjectNumber: string;
	ProjectStatus: string;
	CustomerNumber: { value: string; validate: string };
	Commission: { value: string; validate: string };
	DocumentLastChanged: string;
	DocumentStatus: string;
	DocumentStatusName: string;
	DocumentType: string;
	DocumentTypeName: string;
	PartnerName: string;
	Salesman: string;
	SalesmanRecipient: string;
	PartnerRecipientName: string;
	DocumentConfigurations: any[];
	totalPriceWithoutTax: string;
	totalTax: string;
	finalPrice: string;
	ContactName: string;
	ContactEmail: string;
	ContactTelephone: string;
	Currency: string;
	contacts: any[];
	changeContactModal: boolean;
	selectedContactd: string;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	loading: boolean;
}
class CustomerDocument extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			DocumentId: '',
			DocumentNumber: '',
			ProjectNumber: '',
			ProjectStatus: '',
			CustomerNumber: { value: '', validate: '' },
			Commission: { value: '', validate: '' },
			DocumentLastChanged: '',
			DocumentStatus: '',
			DocumentStatusName: '',
			DocumentType: '',
			DocumentTypeName: '',
			PartnerName: '',
			Salesman: '',
			SalesmanRecipient: '',
			PartnerRecipientName: '',
			DocumentConfigurations: [],
			totalPriceWithoutTax: '',
			totalTax: '',
			finalPrice: '',
			ContactName: '',
			ContactEmail: '',
			ContactTelephone: '',
			Currency: 'EUR',
			contacts: [],
			changeContactModal: false,
			selectedContactd: '',
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			loading: false,
		};
	}

	componentDidMount() {
		if (!Helper.userAdmin() && !Helper.userInstaller()) {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configurations');
		}
		if (!this.props.document.documentId) {
			return;
		}
		this.getDocument();
	}
	getDocument() {
		this.setState({ loading: true });
		const url = '/document/' + this.props.document.documentId + '?brandPartnerId=' + Helper.brandPartnerId();

		Helper.getData<any>(url)
			.then((data) => {
				const state: IState = this.state;
				if (data) {
					state.DocumentId = data.SalesDocumentId;
					state.DocumentNumber = data.DocumentNumber;
					state.ProjectNumber = data.ProjectNumber;
					state.ProjectStatus = Helper.getLocalizedName(data.ProjectStatus);
					state.Currency = data.Currency ?? 'EUR';
					state.CustomerNumber = {
						value: data.CustomerNumber || '',
						validate: '',
					};
					state.Commission = {
						value: data.Commission || '',
						validate: '',
					};
					state.DocumentLastChanged = Helper.dateWithTimeConvert(data.DocumentLastChanged);
					state.DocumentStatus = data.DocumentStatusId;
					state.DocumentStatusName = Helper.getLocalizedName(data.DocumentStatusName);
					state.DocumentType = data.DocumentType;
					state.DocumentTypeName = Helper.getLocalizedName(data.DocumentTypeName);
					state.totalPriceWithoutTax = data.TotalPrice.toLocaleString('de-DE', {
						style: 'currency',
						currency: state.Currency,
					});
					state.totalTax = data.TotalTaxCost.toLocaleString('de-DE', {
						style: 'currency',
						currency: state.Currency,
					});
					state.finalPrice = data.TotalPriceWithTax.toLocaleString('de-DE', {
						style: 'currency',
						currency: state.Currency,
					});
					let partner = '';
					if (data.PartnerName) {
						partner = data.PartnerName.replace('null', '');
						partner = Helper.getLocalizedName(partner);
					}
					state.PartnerName = partner;
					let partnerRecipient = '';
					if (data.RecipientPartnerName) {
						partnerRecipient = Helper.getLocalizedName(data.RecipientPartnerName.replace('null', ''));
					}
					state.PartnerRecipientName = partnerRecipient;
					state.Salesman = data.Salesman;
					state.SalesmanRecipient = data.SalesmanRecipient;
					state.DocumentConfigurations = data.Configurations;
					state.ContactName = data.ContactName;
					state.ContactEmail = data.ContactEmail;
					state.ContactTelephone = data.ContactTelephone;
					this.setState(state);
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	handleChange(e: any) {
		const state: any = this.state;
		const { target } = e;
		const value = target.value;
		state[target.name].value = value;
		state.Saved = false;
		this.setState(state);
	}
	handleRowSelect(row: any) {
		this.setState({ selectedContactd: row.ContactId });
	}
	toggleConfirmationModal(title?: string, message?: string, action?: string) {
		const state: IState = this.state;
		state.confirmationModal = !state.confirmationModal;
		if (title && message && action) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.confirmationModalAction = action;
		}

		this.setState(state);
	}
	confirmationAction(action: string) {
		if (action === 'changeContact') {
			this.changeContact();
		}
		this.setState({ confirmationModal: false });
	}
	buttonSaveClick() {
		this.setState({ loading: true }, () => this.saveDocument());
	}
	toggleChangeContactModal() {
		const url = '/brand/' + Helper.brandPartnerId() + '/contacts';
		const state: IState = this.state;
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					state.contacts = data;
				}

				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});

		state.changeContactModal = !state.changeContactModal;
		this.setState(state);
	}
	saveDocument() {
		const url = '/document/' + this.props.document.documentId;

		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Commission: this.state.Commission.value,
			CustomerNumber: this.state.CustomerNumber.value,
		};
		Helper.putData<any>(url, JSON.stringify(body)).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.setState({ loading: false });
		});
	}
	changeContact() {
		this.setState({
			loading: true,
			changeContactModal: false,
		});
		const documentId = this.state.DocumentId;
		const contactId = this.state.selectedContactd;

		if (documentId && contactId) {
			const url = '/document/' + documentId + '/changecontact';
			const body = {
				ContactId: contactId,
				BrandPartnerId: Helper.brandPartnerId(),
			};
			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data) {
						if (data.Message && data.Message.length > 0) {
							(this.props as any).setError(locale.assignDocumentToContactTitle, data.Message);
							this.setState({
								loading: false,
							});
						} else {
							this.setState(
								{
									loading: false,
								},
								() => this.getDocument()
							);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	render() {
		const state: IState = this.state;
		const disabled = state.CustomerNumber.validate === 'has-danger';
		const disableEditDocument = this.props.document && this.props.document.disableEdit;

		var selectRowProp: any = {
			mode: 'radio',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: [this.state.selectedContactd],
			onSelect: (row: any) => this.handleRowSelect(row),
		};
		const options = {
			sizePerPage: 10,
			noDataText: locale.noResults,
		};

		return (
			<div id='configurator' className='admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background'>
							<Row>
								<Col xs='12' className='mt-3'>
									<Form>
										<Row form>
											<Col md={12} className='admin-form-border'>
												<Row form>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label className='special-bold'>{locale.adminDocumentPartner}</Label>
															<Input className='special-bold' type='text' disabled={true} defaultValue={state.PartnerName} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label>{locale.salesmanResponsible}</Label>
															<Input type='text' disabled={true} defaultValue={state.Salesman} />
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label>{locale.adminDocumentPartnerRecipient}</Label>
															<Input type='text' disabled={true} defaultValue={state.PartnerRecipientName} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label>{locale.salesman}</Label>
															<Input type='text' disabled={true} defaultValue={state.SalesmanRecipient} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label>{locale.adminDocumentContact}</Label>
															<Input type='text' disabled={true} defaultValue={state.ContactName} />
															<Button
																aria-label='Close'
																className='btn-change'
																style={{ backgroundColor: Helper.themeHeaderColor(), color: Helper.themeHeaderFontColor() }}
																disabled={disableEditDocument}
																onClick={() => this.toggleChangeContactModal()}
															>
																<i
																	className='fa fa-address-book'
																	style={{ marginLeft: '0px', paddingRight: '0px', fontSize: '22px', color: Helper.themeHeaderFontColor() }}
																	aria-hidden='true'
																></i>
															</Button>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={4} xl={4}>
														<FormGroup onClick={() => (window.location.href = `mailto:${state.ContactEmail}`)}>
															<Label>{locale.email}</Label>
															<Input
																style={{
																	cursor: 'pointer',
																}}
																type='text'
																disabled={true}
																defaultValue={state.ContactEmail}
															/>
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label>{locale.telephone}</Label>
															<Input type='text' disabled={true} defaultValue={state.ContactTelephone} />
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='DocumentNumber'>{locale.adminDocumentDocumentNumber}</Label>
															<Input type='text' name='DocumentNumber' id='DocumentNumber' disabled={true} defaultValue={state.DocumentNumber} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label>{locale.documentType}</Label>
															<Input type='text' disabled={true} defaultValue={state.DocumentTypeName} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='status'>{locale.adminDocumentStatus}</Label>
															<Input type='text' name='status' id='status' disabled={true} defaultValue={state.DocumentStatusName} />
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='ProjectNumber'>{locale.adminDocumentProject}</Label>
															<Input type='text' name='ProjectNumber' id='ProjectNumber' disabled={true} defaultValue={state.ProjectNumber} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='ProjectStatus'>{locale.adminDocumentProjectStatus}</Label>
															<Input type='text' name='ProjectStatus' id='ProjectStatus' disabled={true} defaultValue={state.ProjectStatus} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='configDate'>{locale.adminDocumentDateModified}</Label>
															<Input type='text' disabled={true} name='configDate' id='configDate' defaultValue={state.DocumentLastChanged} />
														</FormGroup>
													</Col>
												</Row>
												<Row form className='mt-3'>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='priceWithoutTax'>
																<b>{locale.totalPriceWithoutTax}</b>
															</Label>
															<Input
																type='text'
																name='priceWithoutTax'
																id='priceWithoutTax'
																disabled={true}
																defaultValue={state.totalPriceWithoutTax}
															/>
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='totalTax'>
																<b>{locale.totalTax}</b>
															</Label>
															<Input type='text' name='totalTax' id='totalTax' disabled={true} defaultValue={state.totalTax} />
														</FormGroup>
													</Col>
													<Col md={4} xl={4}>
														<FormGroup>
															<Label for='price'>
																<b>{locale.finalPrice}</b>
															</Label>
															<Input type='text' name='price' id='price' disabled={true} defaultValue={state.finalPrice} />
														</FormGroup>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row form>
											<Col md={12} className='admin-form-border mt-3'>
												<Row form>
													<Col md={4}>
														<FormGroup>
															<Label for='Commission'>{locale.adminDocumentComission}</Label>
															<Input
																type='text'
																name='Commission'
																id='Commission'
																maxLength={255}
																disabled={disableEditDocument}
																value={state.Commission.value}
																valid={state.Commission.validate === 'has-success'}
																invalid={state.Commission.validate === 'has-danger'}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={4}>
														<FormGroup>
															<Label for='CustomerNumber'>{locale.adminDocumentCustomerNumber}</Label>
															<Input
																type='text'
																name='CustomerNumber'
																id='PositCustomerNumberion'
																maxLength={255}
																disabled={disableEditDocument}
																value={state.CustomerNumber.value}
																valid={state.CustomerNumber.validate === 'has-success'}
																invalid={state.CustomerNumber.validate === 'has-danger'}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={2}>
														<FormGroup>
															<Label
																style={{
																	opacity: 0,
																}}
															>
																Save
															</Label>
															<Button disabled={disabled || disableEditDocument} onClick={() => this.buttonSaveClick()}>
																{locale.save}
															</Button>
														</FormGroup>
													</Col>
												</Row>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<Modal backdrop='static' isOpen={this.state.changeContactModal} toggle={() => this.toggleChangeContactModal()} className='inquiry-popup'>
					<ModalHeader toggle={() => this.toggleChangeContactModal()}>{locale.assignDocumentToContactTitle}</ModalHeader>
					<ModalBody>
						<Container>
							<Row>
								<Col xs='12' className='inquiry'>
									<BootstrapTable
										data={this.state.contacts}
										tableStyle={{
											backgroundColor: 'white',
										}}
										options={options}
										striped
										hover
										bordered={false}
										pagination
										keyField='ContactId'
										selectRow={selectRowProp}
									>
										<TableHeaderColumn dataField='ContactId' dataSort hidden>
											ID
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='Name'
											width='25%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.firstAndLastName}
										</TableHeaderColumn>
										<TableHeaderColumn
											columnTitle
											dataField='Title'
											width='25%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.name}
										</TableHeaderColumn>
										<TableHeaderColumn
											columnTitle
											dataField='Partner'
											width='50%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.partner}
										</TableHeaderColumn>
										<TableHeaderColumn
											columnTitle
											dataField='User'
											width='50%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.user}
										</TableHeaderColumn>
									</BootstrapTable>
								</Col>
							</Row>
							<Button
								color='secondary'
								className='my-3'
								onClick={() =>
									this.toggleConfirmationModal(locale.assignDocumentToContactTitle, locale.assignDocumentToContactConfirmation, 'changeContact')
								}
							>
								{locale.save}
							</Button>
							<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleChangeContactModal()}>
								{locale.cancel}
							</Button>
							<a
								href={process.env.REACT_APP_TOKEN_URL + '/Administration/Partners'}
								target='_blank'
								style={{
									backgroundColor: Helper.themeButtonColor(),
									color: Helper.themeButtonFontColor(),
								}}
								rel='noopener noreferrer'
								className='button my-2 ml-3 action-button btn btn-secondary'
							>
								{locale.assignDocumentToContactAddNew}
							</a>
						</Container>
					</ModalBody>
				</Modal>
				<Modal size='lg' isOpen={this.state.confirmationModal} toggle={() => this.toggleConfirmationModal()}>
					<ModalHeader toggle={() => this.toggleConfirmationModal()}>{this.state.confirmationModalTitle}</ModalHeader>
					<ModalBody>
						{this.state.confirmationModalMessage}
						<br />
						<br />
						<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationAction(this.state.confirmationModalAction)}>
							{locale.confirm}
						</Button>
						<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
							{locale.cancel}
						</Button>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerDocument);
