import React, { Fragment } from 'react';
import {
	Row,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Label,
	Input,
	Container,
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import '../Configurator.css';
import './Admin.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import locale from '../../Locale';
import Button from '../Button';
import InquirySales from '../InquirySales';
import Helper, { documentSatus, documentType, projectStatus } from '../../Helper';
import Loading from 'react-fullscreen-loading';
import Notify from './../Notify';
import queryString from 'query-string';

interface IProps extends RouteComponentProps<any> {
	part: any;
	selectedParameters: any;
	categories: any;
	configuration: any;
	document: any;
	theme?: any;
	viewType: string;
	brandPartnerId?: string;
	showMessage: any;
}
interface IState {
	selectedId: string;
	selectedUserId: string;
	selectedContactId: string;
	selectedDocumentNumber: string;
	selectedStatus: string;
	selectedProjectId: string;
	selectedProjectStatus: string;
	selectedProjectNumber: string;
	selectedPartner: string;
	selectedType: string;
	selectedContact: string;
	selectedSalesmanRecipient: string;
	documents: any;
	update: boolean;
	loading: boolean;
	saving: boolean;
	error: boolean;
	clickedButton: string;
	inquiryCustomerModal: boolean;
	inquirySellerModal: boolean;
	assignDocumentToUserModal: boolean;
	forwardDocumentToUserModal: boolean;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	users: any[];
	contacts: any[];
	showMessage: any;
	notify: any;
	copyDocumentModal: boolean;
	documentTypes: [];
	documentTypeId: string;
	newProject: boolean;
	assignButton: boolean;
	includeArchived: boolean;
	showAll: boolean;
	languages: any;
	sendERPButton: boolean;
	enabledEdifactImport: boolean;
	enabledEdifactExport: boolean;
}
class CustomerView extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedId: '',
			selectedUserId: '',
			selectedContactId: '',
			selectedDocumentNumber: '',
			selectedStatus: '',
			selectedProjectId: '',
			selectedProjectStatus: '',
			selectedProjectNumber: '',
			selectedPartner: '',
			selectedType: '',
			selectedContact: '',
			selectedSalesmanRecipient: '',
			clickedButton: '',
			documents: [],
			update: false,
			loading: true,
			saving: false,
			error: false,
			inquiryCustomerModal: false,
			inquirySellerModal: false,
			assignDocumentToUserModal: false,
			forwardDocumentToUserModal: false,
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			users: [],
			contacts: [],
			showMessage: this.props.showMessage || null,
			notify: { open: false, title: '', message: '', isError: false },
			copyDocumentModal: false,
			documentTypes: [],
			documentTypeId: documentType.documenttype_offer,
			newProject: true,
			assignButton: false,
			includeArchived: false,
			showAll: false,
			languages: [],
			sendERPButton: false,
			enabledEdifactImport: false,
			enabledEdifactExport: false,
		};
	}
	toggleInquiryCustomerModal() {
		const state: IState = this.state;
		state.inquiryCustomerModal = !state.inquiryCustomerModal;
		this.setState(state);
	}
	toggleInquirySellerModal() {
		const url = '/brand/' + Helper.brandPartnerId() + '/users';
		const state: IState = this.state;
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					state.users = data;
				}

				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});

		state.inquirySellerModal = !state.inquirySellerModal;
		this.setState(state);
	}
	toggleAssignDocumentToBrandPartnerUserModal() {
		const url = '/brand/' + Helper.brandPartnerId() + '/myusers';
		const state: IState = this.state;
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					state.users = data;
				}

				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});

		state.assignDocumentToUserModal = !state.assignDocumentToUserModal;
		this.setState(state);
	}
	toggleForwardDocumentToBrandPartnerUserModal() {
		const url = '/brand/' + Helper.brandPartnerId() + '/contacts';
		const state: IState = this.state;
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					// only contacts with user
					state.contacts = data.filter((x: any) => x.User != null);
				}
				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});

		state.forwardDocumentToUserModal = !state.forwardDocumentToUserModal;
		this.setState(state);
	}
	toggleCopyDocumentModal() {
		const url = '/selection/documenttype/' + Helper.brandPartnerId();
		const state: IState = this.state;
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					let typesFromApi = data.map((type: any) => {
						return { value: type.DocumentTypeId, display: Helper.getLocalizedName(type.Name) };
					});
					state.documentTypes = typesFromApi;
				}

				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});

		state.newProject =
			!this.state.selectedProjectId ||
			this.state.selectedProjectId === '' ||
			this.state.selectedProjectId === '00000000-0000-0000-0000-000000000000' ||
			this.state.selectedProjectStatus === projectStatus.closed ||
			this.state.selectedProjectStatus === projectStatus.sending ||
			this.state.selectedProjectStatus === projectStatus.sent
				? true
				: false;

		state.copyDocumentModal = !state.copyDocumentModal;
		this.setState(state);
	}
	documentData(configData: any): any {
		let documents: any = [];

		if (configData && configData.length > 0) {
			if (typeof configData !== 'string') {
				configData.forEach((item: any) => {
					let partnerRecipient = '';
					if (item.RecipientPartnerName) {
						partnerRecipient = Helper.getLocalizedName(item.RecipientPartnerName.replace('null', ''));
					}

					let partner = '';
					if (item.PartnerName) {
						partner = Helper.getLocalizedName(item.PartnerName.replace('null', ''));
					}
					documents.push({
						id: item.SalesDocumentId,
						isAnyError: item.IsAnyError,
						documentNumber: item.DocumentNumber,
						projectId: item.ProjectId,
						projectStatusId: item.ProjectStatusId,
						projectNumber: item.ProjectNumber,
						projectStatus: Helper.getLocalizedName(item.ProjectStatus),
						dateChanged: Helper.dateWithTimeConvert(item.DocumentLastChanged),
						dateChangedDate: item.DocumentLastChanged,
						partner: partner,
						partnerRecipient: partnerRecipient,
						contact: item.ContactName,
						companyName: item.CompanyName,
						comission: item.Commission,
						customerDocumentNumber: item.CustomerNumber,
						value: item.TotalPriceWithTax,
						type: Helper.getLocalizedName(item.DocumentTypeName),
						status: Helper.getLocalizedName(item.DocumentStatusName),
						statusId: item.DocumentStatusId,
						salesman: item.Salesman,
						salesmanRecipient: item.SalesmanRecipient,
						typeId: item.DocumentTypeId,
						initialSalesDocumentId: item.InitialSalesDocumentId,
						initialSalesDocumentNumber: item.InitialSalesDocumentNumber,
						initialSalesDocument: item.InitialSalesDocumentNumber,
						currency: item.Currency,
					});
				});
			}
		}
		return documents;
	}

	setUserContactData(documentTypeId: string, projectId: string, newProject: boolean, contactData: any, contactId: string) {
		if ((contactData && contactData.Email !== '') || contactId !== '') {
			this.toggleInquiryCustomerModal();
			this.setState({ loading: true });

			const url = '/document';
			const body = {
				DocumentTypeId: documentTypeId,
				BrandPartnerId: Helper.brandPartnerId(),
				ProjectId: projectId,
				NewProject: projectId !== '' ? newProject : true,
				ContactId: contactId,
				ConfigurationContact: contactData,
			};
			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data.Message && data.Message.length > 0) {
						(this.props as any).setError(locale.exception, data.Message);
						this.setState({ loading: false });
					} else if (data) {
						this.setConfiguration(data.SalesDocumentId);
						this.setState({ loading: false });
					} else this.setState({ loading: false });
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	componentDidMount() {
		this.getDocuments((this.props.document && this.props.document.documentId) || '');
		this.getLanguages();
		(this.props as any).configuration.loader = false;
		const state: IState = this.state;
		if (state.showMessage && state.showMessage.show) {
			state.notify.title = state.showMessage.title;
			state.notify.message = state.showMessage.message;
			(this.props as any).setShowMessage(false, '', '');
			state.notify.open = true;
			this.setState(state);
		}
	}
	componentDidUpdate(nextProps: any) {
		if (nextProps.brandPartnerId !== this.props.brandPartnerId) {
			this.getDocuments((this.props.document && this.props.document.documentId) || '');
		}
	}
	getDocuments(selectedId: string) {
		const state: IState = this.state;
		let url = '';
		if (this.props.viewType === 'all') {
			url = '/document/' + Helper.brandPartnerId() + '/all';
			if (this.state.includeArchived) {
				url += 'All';
			}
			if (!this.state.showAll) {
				url += 'Limit';
			}
		} else {
			url = '/document/' + Helper.brandPartnerId() + '/my';
			if (this.state.includeArchived) {
				url += 'All';
			}
			if (!this.state.showAll) {
				url += 'Limit';
			}
		}

		Helper.getData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
					state.loading = false;
					state.error = true;
					this.setState(state);
				} else {
					const documents = this.documentData(data);
					if (selectedId !== '') {
						const selectedItem = documents.find((item: any) => item.id === selectedId);
						if (selectedItem) {
							state.selectedId = selectedItem.id;
							state.selectedDocumentNumber = selectedItem.documentNumber;
							state.selectedStatus = selectedItem.statusId;
							state.selectedType = selectedItem.typeId;
							state.selectedProjectId = selectedItem.projectId;
							state.selectedProjectStatus = selectedItem.projectStatusId;
							state.selectedProjectNumber = selectedItem.projectNumber;
							state.selectedPartner = selectedItem.partnerRecipient;
							state.selectedSalesmanRecipient = selectedItem.salesmanRecipient;
							state.selectedContact = selectedItem.contact;
						}
					} else {
						state.selectedId = '';
						state.selectedDocumentNumber = '';
						state.selectedStatus = '';
						state.selectedType = '';
						state.selectedProjectId = '';
						state.selectedProjectStatus = '';
						state.selectedProjectNumber = '';
						state.selectedPartner = '';
						state.selectedSalesmanRecipient = '';
						state.selectedContact = '';
					}
					state.loading = false;
					state.documents = documents;
					this.setState(state);
				}
			})
			.catch((error: any) => {
				this.setState({ loading: false });			
				(this.props as any).setError(locale.exception, error.message);
			});

		Helper.getData<any>('/account/licence')
			.then((data) => {
				state.enabledEdifactExport = data.EnabledEdifactExport;
				state.enabledEdifactImport = data.EnabledEdifactImport;
				this.setState(state);
			})
			.catch((error: any) => {
				this.setState({ loading: false });
				(this.props as any).setError(locale.exception, error.message);
			});
	}
	getLanguages() {
		const state: any = this.state;
		const url = '/selection/language';

		Helper.getData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
					state.loading = false;
					this.setState(state);
				} else {
					state.languages = data;
					data.map((language: any) => {
						if (language.IsDefault) {
							state.selectedLanguage.name = language.Name;
							state.selectedLanguage.value = language.LanguageId;
						}
						return null;
					});
				}
				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	getConfigurationsList(documentId: string, path: string) {
		if (!documentId) {
			return;
		}
		const url = '/document/' + documentId + '?brandPartnerId=' + Helper.brandPartnerId();
		Helper.getData<any>(url).then((data: any) => {
			if (data.Message) {
				(this.props as any).setError(locale.exception, data.Message);
			} else if (data) {
				(this.props as any).clearPart();
				if (data.InitialSalesDocumentId) {
					(this.props as any).setDocument(
						documentId,
						data.InitialSalesDocumentId,
						data.DocumentNumber,
						data.ProjectNumber,
						data.Configurations,
						data.IsReadOnly,
						data.DocumentStatusId,
						data.Currency
					);
				}
				this.props.history.push(process.env.REACT_APP_ROOT_PATH + path);
			}
		});
	}
	delete() {
		const url = '/document/' + this.state.selectedId + '/archive';
		Helper.deleteData<any>(url)
			.then((saveData) => {
				if (saveData.Message && saveData.Message.length > 0) {
					(this.props as any).setError(locale.deleting, saveData.Message);
				} else {
					this.setState({ saving: false, clickedButton: '' }, () => this.getDocuments(''));
				}
			})
			.catch(() => (this.props as any).setError(locale.deleting, locale.generalException));
	}
	setConfiguration(documentId: string) {
		(this.props as any).setDocument(documentId, '', null, null);
		this.getConfigurationsList(documentId, '/admin/document/configurations');
	}
	toggleConfirmationModal(title?: string, message?: string, action?: string) {
		const state: IState = this.state;
		state.confirmationModal = !state.confirmationModal;
		if (title && message && action) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.confirmationModalAction = action;
		}

		this.setState(state);
	}
	confirmationAction(action: string) {
		if (action === 'delete') {
			this.delete();
		} else if (action === 'confirm') {
			this.confirm();
		} else if (action === 'send') {
			this.sendDocument();
		}
		this.setState({ confirmationModal: false });
	}
	toggleAssignButton() {
		const state: IState = this.state;
		state.assignButton = !state.assignButton;
		this.setState(state);
	}
	toggleSendERPButton() {
		const state: IState = this.state;
		state.sendERPButton = !state.sendERPButton;
		this.setState(state);
	}
	handleRowSelect(row: any) {
		const state: IState = this.state;
		state.selectedId = row.id;
		state.selectedDocumentNumber = row.documentNumber;
		state.selectedStatus = row.statusId;
		state.selectedProjectId = row.projectId;
		state.selectedProjectStatus = row.projectStatusId;
		state.selectedProjectNumber = row.projectNumber;
		state.selectedType = row.typeId;
		state.selectedPartner = row.partnerRecipient;

		state.selectedContact = row.contact;
		state.selectedSalesmanRecipient = row.salesmanRecipient;

		state.saving = false;
		state.clickedButton = '';
		state.update = !state.update;
		state.newProject =
			row.projectStatusId === projectStatus.closed || row.projectStatusId === projectStatus.sending || row.projectStatusId === projectStatus.sent;
		this.setState(state);
	}
	handleUserRowSelect(row: any) {
		const state: IState = this.state;
		state.selectedUserId = row.PartnerUserId;
		this.setState(state);
	}
	handleContactRowSelect(row: any) {
		const state: IState = this.state;
		state.selectedContactId = row.ContactId;
		this.setState(state);
	}
	handleCheckbox(e: any) {
		const state: IState = this.state;
		const { target } = e;
		if (target.name === 'NewProject') {
			state.newProject = !state.newProject;
		}
		this.setState(state);
	}
	cellCheckbox() {
		return <input type='checkbox' />;
	}
	customerProcess(editTitle: string) {
		this.setConfiguration(this.state.selectedId);
	}
	toggleProjectStatus() {
		this.setState({
			saving: true,
			clickedButton: 'toggleProjectStatus',
		});
		const documentId = this.state.selectedId;
		if (documentId) {
			let url = '/document/' + documentId + '/toggle';
			Helper.postData<any>(url, '').then((saveData) => {
				if (saveData) {
					if (saveData.Message && saveData.Message.length > 0) {
						(this.props as any).setError(locale.toggleProjectStatus, saveData.Message);
						this.setState({
							saving: false,
							clickedButton: '',
						});
					} else {
						this.setState({ saving: false, clickedButton: '' }, () => this.getDocuments(documentId));
					}
				}
			});
		}
	}
	confirm() {
		this.setState({
			saving: true,
			clickedButton: 'confirm',
		});
		const documentId = this.state.selectedId;
		if (documentId) {
			let url = '/document/' + documentId + '/confirm?brandPartnerId=' + Helper.brandPartnerId();
			Helper.postData<any>(url, '').then((saveData) => {
				if (saveData) {
					if (saveData.Message && saveData.Message.length > 0) {
						(this.props as any).setError(locale.customerConfirmOrder, saveData.Message);
						this.setState({
							saving: false,
							clickedButton: '',
						});
					} else {
						this.setState({ saving: false, clickedButton: '' }, () => this.getDocuments(documentId));
					}
				}
			});
		}
	}
	sendDocument() {
		this.setState({
			saving: true,
			clickedButton: 'sendDocument',
		});
		const documentId = this.state.selectedId;
		if (documentId) {
			let url = '/document/' + documentId + '/send?brandPartnerId=' + Helper.brandPartnerId();
			Helper.postData<any>(url, '').then((saveData) => {
				if (saveData) {
					if (saveData.Message && saveData.Message.length > 0) {
						(this.props as any).setError(locale.customerConfirmOrder, saveData.Message);
						this.setState({
							saving: false,
							clickedButton: '',
						});
					} else {
						this.setState({ saving: false, clickedButton: '' }, () => this.getDocuments(documentId));
					}
				}
			});
		}
	}
	copyDocument() {
		this.setState({
			saving: true,
			clickedButton: 'copyDocument',
			copyDocumentModal: false,
		});
		const documentId = this.state.selectedId;
		const documentTypeId = this.state.documentTypeId;

		if (documentId && documentTypeId) {
			const url = '/document';
			const body = {
				DocumentTypeId: documentTypeId,
				BrandPartnerId: Helper.brandPartnerId(),
				DocumentId: documentId,
				NewProject: this.state.newProject,
			};
			Helper.postData<any>(url, JSON.stringify(body)).then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.customerConfirmOrder, data.Message);
					this.setState({
						saving: false,
						clickedButton: '',
						copyDocumentModal: false,
						newProject: false,
						documentTypeId: documentType.documenttype_offer,
					});
				} else if (data) {
					this.setState(
						{
							saving: false,
							copyDocumentModal: false,
							newProject: false,
							documentTypeId: documentType.documenttype_offer,
						},
						() => this.getDocuments(data.SalesDocumentId)
					);
				}
			});
		}
	}
	sendToERP(languageCode: string) {
		this.setState({
			saving: true,
			clickedButton: 'sendToERP',
		});
		const documentId = this.state.selectedId;
		if (documentId) {
			let url = '/document/' + documentId + '/download/edifactxml?language=' + languageCode;
			Helper.getData<any>(url)
				.then((saveData) => {
					if (saveData) {
						if (saveData.Message && saveData.Message.length > 0) {
							(this.props as any).setError(locale.customerSendERP, saveData.Message);
							this.setState({
								saving: false,
								clickedButton: '',
							});
						} else if (saveData.FileName) {
							const value = saveData;
							let link = document.createElement('a');
							link.download = value.FileName;
							link.href = `data:${value.MimeType};base64,${value.Contents}`;
							link.click();
							this.setState({ saving: false, clickedButton: '' }, () => this.getDocuments(documentId));
						} else {
							(this.props as any).setError(locale.customerSendERP, '');
							this.setState({
								saving: false,
								clickedButton: '',
							});
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	importEdi() {
		(this.refs.fileUploader as HTMLElement).click();
	}
	onFileChange(event: any) {
		if (event.target.files && event.target.files[0]) {
			const fileName = event.target.files[0].name;
			let reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e: any) => {
				this.importDocument(e.target.result.split(',')[1], fileName);
			};
		}
	}
	importDocument(file: any, fileName: string) {
		this.setState({
			saving: true,
			clickedButton: 'importDocument',
		});
		const documentId = this.state.selectedId;

		if (documentId) {
			const url = '/document/' + documentId + '/upload/edifactxml';
			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				Base64File: file,
				FileName: fileName,
			};
			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data.Message && data.Message.length > 0) {
						(this.props as any).setError(locale.customerImportEdi, data.Message);
						this.setState({
							saving: false,
							clickedButton: '',
						});
					} else if (data) {
						if (data.SalesDocumentId) {
							this.setState({ saving: false, clickedButton: '' }, () => this.getDocuments(data.SalesDocumentId));
						} else {
							(this.props as any).setError(locale.customerImportEdi, data);
							this.setState(
								{
									saving: false,
									clickedButton: '',
								},
								() => this.getDocuments(documentId)
							);
						}
					}
				})
				.catch(() => (this.props as any).setError(locale.customerImportEdi, locale.generalException));
		}
	}
	renderCategoriesRecursion(categories: any): any {
		if (!categories) {
			return [];
		}
		const documentsList = categories.map(function (category: any, i: number) {
			let parameterList = [];
			let sum = 0;

			parameterList = category.parameters.map(function (parameter: any, j: number) {
				sum += parameter.price;

				return (
					<React.Fragment key={j}>
						<tr>
							<td>{parameter.name}</td>
							<td>{parameter.values && parameter.values.length > 0 ? parameter.values[0].name || parameter.values[0].valueName : null}</td>
							<td>
								<div className='money-col'>
									{parameter.price ? (
										parameter.price.toLocaleString('de-DE', {
											style: 'currency',
											currency: 'EUR',
										})
									) : (
										<span>&nbsp;</span>
									)}
								</div>
							</td>
						</tr>
					</React.Fragment>
				);
			});
			return (
				<React.Fragment key={i}>
					<thead>
						<tr>
							<th colSpan={2} className='th-left' scope='col' style={{ width: '75%' }}>
								{category.name}
							</th>
							<th className='th-right' scope='col' style={{ width: '25%' }}>
								<div className='money-col'>{sum} €</div>
							</th>
						</tr>
					</thead>
					<tbody>{parameterList}</tbody>
				</React.Fragment>
			);
		});
		return documentsList;
	}
	sendToProduction() {
		this.setState({
			saving: true,
			clickedButton: 'sendToProduction',
		});
		const documentId = this.state.selectedId;
		if (documentId) {
			let url = '/ref/clone?documentId=' + documentId;
			Helper.postData<any>(url, '').then((saveData) => {
				if (saveData) {
					if (saveData.Message && saveData.Message.length > 0) {
						(this.props as any).setError(locale.submitForProduction, saveData.Message);
						this.setState({ saving: false });
					} else {
						this.setState({ saving: false }, () => this.getDocuments(this.state.selectedId));
					}
				}
			});
		}
	}
	assignSeller() {
		this.setState({
			saving: true,
			clickedButton: 'assignSeller',
			assignDocumentToUserModal: false,
		});
		const documentId = this.state.selectedId;
		const partnerUserId = this.state.selectedUserId;

		if (documentId && partnerUserId) {
			const url = '/document/' + documentId + '/assign';
			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				PartnerUserId: partnerUserId,
			};
			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data) {
						if (data.Message && data.Message.length > 0) {
							(this.props as any).setError(locale.assignDocument, data.Message);
							this.setState({
								saving: false,
								inquirySellerModal: false,
							});
						} else {
							this.setState(
								{
									saving: false,
									inquirySellerModal: false,
								},
								() => this.getDocuments(documentId)
							);
						}
						(this.props as any).clearPart(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	forwardDocument() {
		this.setState({
			saving: true,
			clickedButton: 'forwardDocument',
			forwardDocumentToUserModal: false,
		});

		const documentId = this.state.selectedId;
		const contactId = this.state.selectedContactId;

		if (documentId && contactId) {
			const url = '/document/' + documentId + '/forward';
			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				ContactId: contactId,
			};
			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data) {
						if (data.Message && data.Message.length > 0) {
							(this.props as any).setError(locale.assignDocument, data.Message);
							this.setState({
								saving: false,
								inquirySellerModal: false,
							});
						} else {
							this.setState(
								{
									saving: false,
									inquirySellerModal: false,
								},
								() => this.getDocuments(documentId)
							);
						}
						(this.props as any).clearPart(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	newVersion() {
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/configuration');
	}
	newOffer() {
		(this.props as any).clearPart(true);
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/selection');
	}
	onDoubleClick(row: any) {
		(this.props as any).clearPart(true);
		(this.props as any).setDocument(row.id, '', row.documentNumber, row.projectNumber, null);
		this.getConfigurationsList(row.id, '/admin/document/configurations');
	}
	onClickInitialDocument(row: any) {
		(this.props as any).clearPart(true);
		(this.props as any).setDocument(row.initialSalesDocumentId, '', row.InitialSalesDocumentNumber, null, null);
		this.getConfigurationsList(row.initialSalesDocumentId, '/admin/document/configurations');
	}
	onClickChangeRecipient() {
		this.setState({ confirmationModal: false });
		(this.props as any).clearPart(true);
		(this.props as any).setDocument(this.state.selectedId, '', this.state.selectedDocumentNumber, null, null);
		this.getConfigurationsList(this.state.selectedId, '/admin/document/document');
	}

	handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
		const { currentTarget } = event;
		const value = currentTarget.type === 'checkbox' ? currentTarget.checked : currentTarget.value;
		const { name } = currentTarget;
		await this.setState({
			[name]: value,
		} as unknown as Pick<IState, keyof IState>);

		this.setState({ loading: true });
		this.getDocuments('');
	};

	render() {
		const state = this.state;
		const {
			documents,
			selectedId,
			selectedUserId,
			selectedContactId,
			selectedStatus,
			selectedProjectStatus,
			selectedType,
			selectedPartner,
			selectedSalesmanRecipient,
			selectedContact,
			confirmationModal,
			confirmationModalAction,
			confirmationModalMessage,
			confirmationModalTitle,
			loading,
			saving,
			error,
			users,
			contacts,
			documentTypes,
			documentTypeId,
			enabledEdifactExport,
			enabledEdifactImport,
		} = this.state;

		var selectRowProp: any = {
			mode: 'radio',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: [selectedId],
			onSelect: (row: any) => this.handleRowSelect(row),
		};
		var selectUserRowProp: any = {
			mode: 'radio',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: [selectedUserId],
			onSelect: (row: any) => this.handleUserRowSelect(row),
		};
		var selectContactRowProp: any = {
			mode: 'radio',
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: [selectedContactId],
			onSelect: (row: any) => this.handleContactRowSelect(row),
		};

		const isDisabled = selectedId === '';
		const isForSubmitForProduction =
			(selectedStatus === documentSatus.documentstatus_confirmed || selectedStatus === documentSatus.documentstatus_confirmedOem) &&
			(selectedType === documentType.documenttype_offer ||
				selectedType === documentType.documenttype_order ||
				selectedType === documentType.documenttype_orderconfirmation);

		const isCanceled = selectedStatus === documentSatus.documentstatus_archived || isDisabled;
		const isProjectClosed =
			selectedProjectStatus === projectStatus.closed ||
			selectedProjectStatus === projectStatus.sending ||
			selectedProjectStatus === projectStatus.sent;
		const isProjectActive =
			selectedProjectStatus === projectStatus.active ||
			selectedProjectStatus === '' ||
			selectedProjectStatus === '00000000-0000-0000-0000-000000000000';
		const canSend = isProjectActive && selectedPartner !== '' && selectedStatus !== documentSatus.documentstatus_offer;
		const canConfirm =
			(selectedType === documentType.documenttype_orderconfirmation || selectedType === documentType.documenttype_offer) &&
			selectedStatus !== documentSatus.documentstatus_confirmed &&
			selectedStatus !== documentSatus.documentstatus_confirmedOem;
		const canForward = selectedStatus === documentSatus.documentstatus_received || selectedStatus === documentSatus.documentstatus_forwarded;
		const generatingTechDoc = selectedStatus === documentSatus.documentstatus_generatingtechdocumentation;
		const queryValues = queryString.parse(this.props.location.search);
		const projectFilter = queryValues && queryValues.project ? queryValues.project.toString() : '';

		let loadingDiv = null;
		if (loading || saving) {
			loadingDiv = <Loading loading loaderColor={Helper.themeButtonColor()} />;
		}

		const options = {
			onRowDoubleClick: (row: any) => this.onDoubleClick(row),
			noDataText: locale.noResults,
			sizePerPage: 10,
		};
		const dateSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (new Date(a.dateChangedDate) < new Date(b.dateChangedDate)) return 1;
				if (new Date(a.dateChangedDate) > new Date(b.dateChangedDate)) return -1;
			} else if (order === 'desc') {
				if (new Date(a.dateChangedDate) > new Date(b.dateChangedDate)) return 1;
				if (new Date(a.dateChangedDate) < new Date(b.dateChangedDate)) return -1;
			}
			return 0;
		};
		const documentNumberSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (parseInt(a.documentNumber) < parseInt(b.documentNumber)) return 1;
				if (parseInt(a.documentNumber) > parseInt(b.documentNumber)) return -1;
			} else if (order === 'desc') {
				if (parseInt(a.documentNumber) > parseInt(b.documentNumber)) return 1;
				if (parseInt(a.documentNumber) < parseInt(b.documentNumber)) return -1;
			}
			return 0;
		};

		const projectSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (parseInt(a.projectNumber) < parseInt(b.projectNumber)) return 1;
				if (parseInt(a.projectNumber) > parseInt(b.projectNumber)) return -1;
			} else if (order === 'desc') {
				if (parseInt(a.projectNumber) > parseInt(b.projectNumber)) return 1;
				if (parseInt(a.projectNumber) < parseInt(b.projectNumber)) return -1;
			}
			return 0;
		};
		const floatSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (parseFloat(a.value) < parseFloat(b.value)) return 1;
				if (parseFloat(a.value) > parseFloat(b.value)) return -1;
			} else if (order === 'desc') {
				if (parseFloat(a.value) > parseFloat(b.value)) return 1;
				if (parseFloat(a.value) < parseFloat(b.value)) return -1;
			}
			return 0;
		};
		const linkFormatter = (cell: any, row: any) => {
			if (row.initialSalesDocument) {
				return (
					<button className='btn link-button' onClick={() => this.onClickInitialDocument(row)}>
						{row.initialSalesDocument}
					</button>
				);
			} else {
				return '';
			}
		};
		const indicator = (cell: any, row: any) => {
			if (row.isAnyError) {
				return <i className='fa fa-exclamation' aria-hidden='true' style={{ color: 'red' }} title={row.status}></i>;
			} else {
				return '';
			}
		};
		const priceFormatter = (cell: any, row: any) => {
			if (cell) {
				return cell.toLocaleString('de-DE', {
					style: 'currency',
					currency: row.currency != null ? row.currency : 'EUR',
				});
			} else {
				return 0;
			}
		};

		const languages =
			Array.isArray(state.languages) &&
			state.languages.map((language: any) => {
				return (
					<DropdownItem
						key={language.LanguageId}
						onClick={() => {
							this.sendToERP(language.Code);
						}}
					>
						{language.Name}
					</DropdownItem>
				);
			});
		return (
			<div id='configurator' className='admin '>
				<div>
					{this.state.notify.open ? (
						<Notify message={this.state.notify.message} isError={this.state.notify.isError} title={this.state.notify.title} />
					) : null}
					<Row className='configurator-background customer-view'>
						<Col xs='12' className='customer-table mt-3 '>
							{loadingDiv ? (
								loadingDiv
							) : (
								<Fragment>
									<Col>
										<Row className='admin-form-border'>
											<Col style={{ marginLeft: '10px', marginTop: '10px' }}>
												<Input
													type='checkbox'
													name='includeArchived'
													id='includeArchived-checkbox'
													checked={this.state.includeArchived}
													onChange={this.handleChange.bind(this)}
												/>
												<p className='checkbox' style={{ marginLeft: '5px' }}>
													{locale.includeArchived}
												</p>
											</Col>
											<Col style={{ marginLeft: '10px', marginTop: '10px' }}>
												<Input
													type='checkbox'
													name='showAll'
													id='showAll-checkbox'
													checked={this.state.showAll}
													onChange={this.handleChange.bind(this)}
												/>
												<p className='checkbox' style={{ marginLeft: '5px' }}>
													{locale.showAll}
												</p>
											</Col>
										</Row>
									</Col>
									<BootstrapTable
										data={documents}
										tableStyle={{
											backgroundColor: 'white',
											display: loadingDiv ? 'none' : 'inherit',
										}}
										options={options}
										selectRow={selectRowProp}
										striped
										hover
										bordered={false}
										pagination
										keyField='id'
									>
										<TableHeaderColumn dataField='image' dataFormat={indicator} width='30px'>
											{' '}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='projectNumber'
											width='78px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												defaultValue: projectFilter,
												placeholder: 'Filter',
											}}
											dataSort
											sortFunc={projectSort}
										>
											{locale.adminDocumentProject}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='projectStatus'
											width='78px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
										>
											{locale.adminDocumentProjectStatus}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='documentNumber'
											width='78px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
											sortFunc={documentNumberSort}
										>
											{locale.adminDocumentDocumentOrder}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='dateChanged'
											width='142px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
											sortFunc={dateSort}
										>
											{locale.dataLastChange}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='partner'
											width='180px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
											hidden={this.props.viewType === 'my'}
										>
											{locale.adminDocumentPartner}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='salesman'
											width='150px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											hidden={this.props.viewType === 'my'}
											dataSort
										>
											{locale.salesmanResponsible}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='companyName'
											width='180px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
										>
											{locale.partner}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='salesmanRecipient'
											width='150px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											hidden={this.props.viewType === 'my'}
											dataSort
										>
											{locale.salesman}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='comission'
											width='150px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
										>
											{locale.commission}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='customerDocumentNumber'
											width='100px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
										>
											{locale.adminDocumentBuyerOrderNumber}
										</TableHeaderColumn>

										<TableHeaderColumn
											dataField='initialSalesDocument'
											width='80px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataFormat={linkFormatter}
											dataSort
										>
											{locale.sourceDocument}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='value'
											width='100px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: '',
												style: { opacity: 0 },
											}}
											dataFormat={priceFormatter}
											dataSort
											sortFunc={floatSort}
											dataAlign='right'
											headerAlign='right'
										>
											{locale.finalPrice}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='type'
											width='90px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
										>
											{locale.type}
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='status'
											width='180px'
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
											dataSort
										>
											{locale.status}
										</TableHeaderColumn>
										<TableHeaderColumn dataField='status' hidden>
											{locale.status}
										</TableHeaderColumn>
									</BootstrapTable>
									<Col>
										<Row>
											<Col className='admin-form-border' style={{ marginTop: '-10px' }}>
												<Button color='secondary' className='button mr-2 my-1' onClick={() => this.customerProcess('Inquiry')} disabled={isDisabled}>
													{locale.open}
												</Button>
												{Helper.userAdmin() && this.props.viewType === 'my' ? (
													<Button color='secondary' className='button mr-2 my-1' onClick={() => this.toggleInquiryCustomerModal()} disabled={error}>
														{locale.customerNewOffer}
													</Button>
												) : null}
												{/* {Helper.userAdmin() && this.props.viewType === 'all' ? (
													<Button
														color='secondary'
														className='button mr-2 my-1'
														disabled={isDisabled || !isProjectActiveOrClosed}
														onClick={() => this.toggleProjectStatus()}
													>
														{locale.toggleProjectStatus}
													</Button>
												) : null} */}
												{Helper.userAdmin() && this.props.viewType === 'my' ? (
													<Fragment>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															disabled={isDisabled || !isProjectActive || generatingTechDoc}
															onClick={() => this.toggleAssignDocumentToBrandPartnerUserModal()}
														>
															{locale.assignDocument}
														</Button>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															disabled={isDisabled || !canSend || generatingTechDoc}
															onClick={() =>
																this.toggleConfirmationModal(
																	locale.customerSendDocument,
																	locale.confirmSendDocument
																		.replace('{Partner}', selectedPartner)
																		.replace('{Salesman}', selectedSalesmanRecipient)
																		.replace('{Contact}', selectedContact),
																	'send'
																)
															}
														>
															{locale.sendDocument}
														</Button>
													</Fragment>
												) : null}
												{Helper.userAdmin() && (this.props.viewType === 'my' || this.props.viewType === 'all') ? (
													<Fragment>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															onClick={() => this.toggleCopyDocumentModal()}
															disabled={isDisabled}
														>
															{locale.copy}
														</Button>
													</Fragment>
												) : null}
												{Helper.userAdmin() && this.props.viewType === 'my' ? (
													<Fragment>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															onClick={() =>
																this.toggleConfirmationModal(
																	locale.customerConfirmOrder,
																	locale.customerConfirmOrderMessage.replace('{number}', this.state.selectedDocumentNumber),
																	'confirm'
																)
															}
															disabled={isDisabled || !canConfirm || !isProjectActive}
														>
															{locale.customerConfirmOrder}
														</Button>
														{Helper.userIsOem() ? (
															<Fragment>
																<Button
																	color='secondary'
																	className='button mr-2 my-1'
																	onClick={() => this.sendToProduction()}
																	disabled={isDisabled || !isForSubmitForProduction || !isProjectActive || generatingTechDoc}
																>
																	{locale.submitForProduction}
																</Button>
															</Fragment>
														) : null}
														{enabledEdifactExport ? (
															<ButtonDropdown direction='up' isOpen={this.state.sendERPButton} toggle={() => this.toggleSendERPButton()}>
																<DropdownToggle
																	style={{ backgroundColor: Helper.themeButtonColor(), color: Helper.themeButtonFontColor(), borderRadius: 3.5 }}
																	className='action-button button mr-2 my-1'
																	disabled={isDisabled || !isProjectActive}
																>
																	{locale.customerSendERP}
																</DropdownToggle>
																{<DropdownMenu>{languages}</DropdownMenu>}
															</ButtonDropdown>
														) : null}
														{enabledEdifactImport ? (
															<>
																<Button
																	color='secondary'
																	className='button mr-2 my-1'
																	onClick={() => this.importEdi()}
																	disabled={isDisabled || !isProjectActive}
																>
																	{locale.customerImportEdi}
																</Button>
																<input
																	type='file'
																	accept='application/xml'
																	onChange={(e) => this.onFileChange(e)}
																	id='file'
																	ref='fileUploader'
																	style={{ display: 'none' }}
																/>
															</>
														) : null}
														<Button
															color='secondary'
															className='button mr-2 my-1'
															onClick={() => this.toggleForwardDocumentToBrandPartnerUserModal()}
															disabled={isDisabled || !canForward || !isProjectActive}
														>
															{locale.customerForward}
														</Button>
													</Fragment>
												) : null}

												{Helper.userAdmin() && this.props.viewType === 'all' ? (
													<Fragment>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															disabled={isDisabled || isCanceled || !isProjectActive || generatingTechDoc}
															onClick={() => this.toggleAssignDocumentToBrandPartnerUserModal()}
														>
															{locale.assignDocument}
														</Button>
													</Fragment>
												) : null}

												<Fragment>
													<Button
														color='secondary'
														className='button mr-2 my-1'
														disabled={isCanceled}
														onClick={() => this.toggleConfirmationModal(locale.archive, locale.confirmArchive, 'delete')}
													>
														{locale.archive}
													</Button>
												</Fragment>
											</Col>
										</Row>
									</Col>
								</Fragment>
							)}
						</Col>
					</Row>
					<Modal size='lg' isOpen={confirmationModal} toggle={() => this.toggleConfirmationModal()}>
						<ModalHeader toggle={() => this.toggleConfirmationModal()}>{confirmationModalTitle}</ModalHeader>
						<ModalBody>
							<div dangerouslySetInnerHTML={{ __html: confirmationModalMessage }} />
							<br />
							<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationAction(confirmationModalAction)}>
								{locale.confirm}
							</Button>
							{confirmationModalAction === 'send' && (
								<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.onClickChangeRecipient()}>
									{locale.changeRecipient}
								</Button>
							)}
							<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
								{locale.cancel}
							</Button>
						</ModalBody>
					</Modal>
					<Modal
						backdrop='static'
						isOpen={this.state.inquiryCustomerModal}
						toggle={() => this.toggleInquiryCustomerModal()}
						className='inquiry-popup'
					>
						<ModalHeader toggle={() => this.toggleInquiryCustomerModal()}>{locale.inquirySalesDialog}</ModalHeader>
						<ModalBody>
							<InquirySales
								project={{
									id: this.state.selectedProjectId,
									number: this.state.selectedProjectNumber,
									status: this.state.selectedProjectStatus,
								}}
								setUserContactData={(documentTypeId: string, projectId: string, newProject: boolean, contactData: any, contactId: string) =>
									this.setUserContactData(documentTypeId, projectId, newProject, contactData, contactId)
								}
							/>
						</ModalBody>
					</Modal>
					<Modal backdrop='static' isOpen={this.state.inquirySellerModal} toggle={() => this.toggleInquirySellerModal()} className='inquiry-popup'>
						<ModalHeader toggle={() => this.toggleInquirySellerModal()}>{locale.assignDocument}</ModalHeader>
						<ModalBody>
							<Container>
								<Row>
									<Col xs='12' className='inquiry'>
										<BootstrapTable
											data={users}
											options={{}}
											tableStyle={{
												backgroundColor: 'white',
											}}
											striped
											hover
											bordered={false}
											pagination
											keyField='PartnerUserId'
											selectRow={selectUserRowProp}
										>
											<TableHeaderColumn dataField='PartnerUserId' dataSort hidden>
												ID
											</TableHeaderColumn>

											<TableHeaderColumn
												columnTitle
												dataField='Name'
												width='50%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.firstAndLastName}
											</TableHeaderColumn>
											<TableHeaderColumn
												columnTitle
												dataField='UserType'
												width='25%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.type}
											</TableHeaderColumn>

											<TableHeaderColumn
												columnTitle
												dataField='Partner'
												width='25%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.company}
											</TableHeaderColumn>
										</BootstrapTable>
									</Col>
								</Row>
								<Button color='secondary' className='my-3' disabled={selectedUserId === ''} onClick={() => this.assignSeller()}>
									{locale.save}
								</Button>
								<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleInquirySellerModal()}>
									{locale.cancel}
								</Button>
							</Container>
						</ModalBody>
					</Modal>

					<Modal
						backdrop='static'
						isOpen={this.state.assignDocumentToUserModal}
						toggle={() => this.toggleAssignDocumentToBrandPartnerUserModal()}
						className='inquiry-popup'
					>
						<ModalHeader toggle={() => this.toggleAssignDocumentToBrandPartnerUserModal()}>{locale.assignDocument}</ModalHeader>
						<ModalBody>
							<Container>
								<Row>
									<Col xs='12' className='inquiry'>
										<BootstrapTable
											data={users}
											options={{}}
											tableStyle={{
												backgroundColor: 'white',
											}}
											striped
											hover
											bordered={false}
											pagination
											keyField='PartnerUserId'
											selectRow={selectUserRowProp}
										>
											<TableHeaderColumn dataField='PartnerUserId' dataSort hidden>
												ID
											</TableHeaderColumn>

											<TableHeaderColumn
												columnTitle
												dataField='Name'
												width='50%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.firstAndLastName}
											</TableHeaderColumn>
											<TableHeaderColumn
												columnTitle
												dataField='UserType'
												width='25%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.type}
											</TableHeaderColumn>
										</BootstrapTable>
									</Col>
								</Row>
								<Button color='secondary' className='my-3' disabled={selectedUserId === ''} onClick={() => this.assignSeller()}>
									{locale.save}
								</Button>
								<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleAssignDocumentToBrandPartnerUserModal()}>
									{locale.cancel}
								</Button>
							</Container>
						</ModalBody>
					</Modal>
					<Modal
						backdrop='static'
						isOpen={this.state.forwardDocumentToUserModal}
						toggle={() => this.toggleForwardDocumentToBrandPartnerUserModal()}
						className='inquiry-popup'
					>
						<ModalHeader toggle={() => this.toggleForwardDocumentToBrandPartnerUserModal()}>{locale.customerForward}</ModalHeader>
						<ModalBody>
							<Container>
								<Row>
									<Col xs='12' className='inquiry'>
										<BootstrapTable
											data={contacts}
											options={{}}
											tableStyle={{
												backgroundColor: 'white',
											}}
											striped
											hover
											bordered={false}
											pagination
											keyField='ContactId'
											selectRow={selectContactRowProp}
										>
											<TableHeaderColumn dataField='ContactId' dataSort hidden>
												ID
											</TableHeaderColumn>

											<TableHeaderColumn
												columnTitle
												dataField='Name'
												width='25%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.firstAndLastName}
											</TableHeaderColumn>
											<TableHeaderColumn
												columnTitle
												dataField='Title'
												width='25%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.name}
											</TableHeaderColumn>
											<TableHeaderColumn
												columnTitle
												dataField='Partner'
												width='50%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.partner}
											</TableHeaderColumn>
											<TableHeaderColumn
												columnTitle
												dataField='User'
												width='50%'
												dataSort
												filter={{
													type: 'TextFilter',
													delay: 500,
													placeholder: 'Filter',
												}}
											>
												{locale.user}
											</TableHeaderColumn>
										</BootstrapTable>
									</Col>
								</Row>
								<Button color='secondary' className='my-3' disabled={selectedContactId === ''} onClick={() => this.forwardDocument()}>
									{locale.save}
								</Button>
								<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleForwardDocumentToBrandPartnerUserModal()}>
									{locale.cancel}
								</Button>
							</Container>
						</ModalBody>
					</Modal>
					<Modal
						backdrop='static'
						isOpen={this.state.copyDocumentModal}
						toggle={() => this.toggleCopyDocumentModal()}
						size='md'
						className='copy-popup'
					>
						<ModalHeader toggle={() => this.toggleCopyDocumentModal()}>{locale.customerConfirmInqury}</ModalHeader>
						<ModalBody>
							<Container>
								<FormGroup>
									<Row>
										<Col xs='12'>
											<div className='form-group'>
												<Label check htmlFor='DocumentTypeId'>
													{' '}
													{locale.inquiryDocumentType}
												</Label>
												<select
													className='form-control'
													value={this.state.documentTypeId}
													onChange={(e) => this.setState({ documentTypeId: e.target.value })}
												>
													{documentTypes.map((type: any) => (
														<option key={type.value} value={type.value}>
															{type.display}
														</option>
													))}
												</select>
											</div>
										</Col>
										<Col xs='12' style={{ marginTop: '5px' }}>
											<div className='form-group'>
												<Input
													type='checkbox'
													name='NewProject'
													id='newproject-checkbox'
													disabled={isProjectClosed}
													checked={this.state.newProject}
													onChange={(e) => {
														this.handleCheckbox(e);
													}}
												/>

												<p className='checkbox'>{locale.customerConfirmInquryNewProject}</p>
											</div>
										</Col>
									</Row>
								</FormGroup>
								<Button color='secondary' className='my-3' disabled={documentTypeId === ''} onClick={() => this.copyDocument()}>
									{locale.save}
								</Button>
								<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleCopyDocumentModal()}>
									{locale.cancel}
								</Button>
							</Container>
						</ModalBody>
					</Modal>
				</div>
			</div>
		);
	}
}
export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerView);
